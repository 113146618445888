import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { StyleSheetManager } from 'styled-components';

import { Box, Grid } from '@core';
import { Animation, Text } from '@components';
import { useTimerContext } from '@context';

const NumberColumn = ({ number, color }) => (
  <Grid.Col width={26}>
    <Text.Body2Strong textAlign="center" fontSize={40} lineHeight="36px" color={color} my={6}>
      {number}
    </Text.Body2Strong>
  </Grid.Col>
);

const TimerItem = ({ value, color }) => {
  return (
    <Grid.Container>
      <Grid.Row width={52} justifyContent="center">
        <NumberColumn number={value[0]} color={color} />
        <NumberColumn number={value[1]} color={color} />
      </Grid.Row>
    </Grid.Container>
  );
};

const Separator = () => (
  <Text.Body2Strong
    as="span"
    lineHeight="40px"
    color="timerRedesigned.numberColor"
    mx={10}
    $fontSize={40}
  >
    :
  </Text.Body2Strong>
);

const TimerWrapper = styled.span`
  direction: ltr;
`;

const DealsTimer = () => {
  const { t } = useTranslation();
  const { hours, minutes, seconds, isClientSideReady } = useTimerContext();

  return (
    <Box minWidth={typeof days === 'undefined' ? 192 : 247} minHeight={76}>
      {isClientSideReady && (
        <Animation.FadeIn>
          <Box borderRadius="md" backgroundColor="timerRedesigned.backgroundColor" py={16} px={24}>
            <Text.Body4Strong
              color="timerRedesigned.titleColor"
              textAlign="center"
              fontSize={14}
              mb={8}
            >
              {t('deals:timer.expiresIn')}
            </Text.Body4Strong>

            <StyleSheetManager stylisPlugins={[]}>
              <TimerWrapper>
                <Box display="flex" alignItems="baseline">
                  <TimerItem
                    value={hours}
                    label={t('deals:timer.hours')}
                    color="timerRedesigned.numberColor"
                  />
                  <Separator />
                  <TimerItem
                    value={minutes}
                    label={t('deals:timer.minutes')}
                    color="timerRedesigned.numberColor"
                  />
                  <Separator />
                  <TimerItem
                    value={seconds}
                    label={t('deals:timer.seconds')}
                    color="timerRedesigned.numberColor"
                  />
                </Box>
              </TimerWrapper>
            </StyleSheetManager>
          </Box>
        </Animation.FadeIn>
      )}
    </Box>
  );
};

export default DealsTimer;
